<template>
  <ul>
    <Tag v-for="tag in tags" :key="tag" :title="tag"></Tag>
  </ul>
</template>

<script>
import Tag from './Tag.vue';

export default {
  props: ['tags'],
  components: { Tag },
};
</script>

<style scoped lang="scss">
ul {
  list-style-type: none;
  text-align: center;
  margin: 0 auto 1rem auto;

  @media (min-width: 576px) {
    max-width: 30rem;
  }
}
</style>
