<template>
  <div class="language-picker">
    <select class="language-picker__select" @change="onLanguageOptionClick" v-model="$i18n.locale">
      <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
        {{ lang }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      langs: ['de', 'en'],
      showLanguagePicker: false,
    };
  },
};
</script>

<style scoped lang="scss">
.language-picker {
  background: white;
  padding: 0 0.25em;
  border: 1px solid lightgrey;

  .language-picker__select {
    -webkit-appearance: none;
    border: 0;
    background: transparent;
    color: black;
    font-size: 20px;
  }
}
</style>
