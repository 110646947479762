<template>
  <div class="project-card">
    <div class="project-card-header">
      <h2>
        {{ $t(title) }}
      </h2>
      <div class="framework-logo-container">
        <img :src="require(`../assets/images/${frameworkLogoLink}`)" />
      </div>
    </div>
    <div class="project-card-body">
      <p>{{ $t(description) }}</p>
    </div>
    <div class="project-card-footer">
      <div class="repository-image-container">
        <img src="../assets/images/gitlab.png" alt="gitlab-logo" />
      </div>
      <a :href="link" target="_blank">
        <button>{{ $t('projects.linkText') }}</button>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectCard',
  props: ['title', 'description', 'framework', 'link'],
  data() {
    return {
      frameworkLogoLink: '',
    };
  },
  beforeMount() {
    switch (this.framework) {
      case 'react':
        this.frameworkLogoLink = 'react.png';
        break;
      case 'vue':
        this.frameworkLogoLink = 'vue.png';
        break;
      case 'java':
        this.frameworkLogoLink = 'java.png';
        break;
      case 'angular':
      default:
        this.frameworkLogoLink = 'angular.png';
    }
  },
};
</script>

<style scoped lang="scss">
.project-card {
  display: grid;
  grid-template-rows: 1fr 4fr 1fr;
  color: white;
  width: 100%;
  height: 20rem;
  background-color: var(--color-secondary);
  margin-bottom: 2rem;
  transition: all 0.2s ease-in-out;
  border: 1px solid var(--color-primary);
  border-radius: 0.5rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover,
  &:focus-visible {
    transform: scale(1.1);
  }

  .project-card-header {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    background: var(--color-primary);
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;

    .framework-logo-container {
      width: 2.5rem;
      height: 2.5rem;
      background-color: white;
      border-radius: 100%;
      padding: 0.3rem;
    }

    h2 {
      margin-bottom: 0;
      transition: color 0.5s;
    }

    h2:hover {
      color: #f56924;
    }

    a {
      color: white;
    }

    img {
      width: 100%;
    }
  }

  .project-card-footer {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    background-color: var(--color-primary);
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    button {
      background-color: var(--color-secondary);
      border: 0;
      padding: 0.5rem;
      border-radius: 0.75rem;
      color: #f56924;
      font-weight: 600;
      transition: background-color 0.5s;
      transition: color 0.5s;
      cursor: pointer;
      font-size: 1rem;

      &:hover,
      &:focus-visible {
        background-color: #f56924;
        color: white;
      }
    }

    img {
      width: 100%;
    }

    .repository-image-container {
      background-color: var(--color-secondary);
      border-radius: 100%;
      width: 2.5rem;
      height: 2.5rem;
      padding: 0.3rem;
    }
  }

  .project-card-body {
    text-align: left;
    padding: 0.75rem;
    overflow-y: auto;
    background-color: var(--color-secondary);
    color: black;
    border: 1px solid var(--color-secondary);

    p {
      color: inherit;
      font-weight: 400;
    }
  }
}
</style>
