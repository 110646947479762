<template>
  <main>
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </main>
</template>

<script>
export default {
  name: 'Main',
  props: {},
};
</script>

<style scoped lang="scss">
main {
  min-height: calc(100vh - var(--header-height));
  max-width: 50rem;
  margin: calc(var(--header-height) + 2rem) auto 0 auto;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
