<template>
  <div class="contact">
    <h1 v-if="$mq !== 'mobile'">
      {{ $t('contact.headline') }}
    </h1>
    <div class="contact-section">
      <section>
        <h2>E-Mail</h2>
        <a href="mailto:maxneitzel@web.de">
          <font-awesome-icon class="contact-icon email-icon" icon="envelope" />
        </a>
      </section>
      <section>
        <h2>XING</h2>
        <a href="https://www.xing.com/profile/Maximilian_Neitzel2" target="_blank">
          <img class="contact-icon" src="@/assets/images/xing-icon.svg" alt="xing-icon" />
        </a>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contact',
};
</script>

<style scoped lang="scss">
.contact {
  width: 100%;

  h1 {
    margin-bottom: 3rem;
  }
}

.contact-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h2 {
    margin-bottom: 1rem;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    gap: 8rem;
  }
}

.contact-icon {
  width: 10rem;
  height: 10rem;
  background-color: white;
  padding: 2.5rem;
  border-radius: 50%;
}

.email-icon {
  color: black;
}
</style>
