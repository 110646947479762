<template>
  <div v-on:click="onCloseIconClick" class="menu-icon">
    <transition name="fade" mode="out-in">
      <font-awesome-icon v-if="isOpen" icon="times" />
      <font-awesome-icon v-else icon="bars" />
    </transition>
  </div>
  <div class="page-title-container">
    <h3 v-if="$mq === 'mobile'">{{ $t($route.name) }}</h3>
  </div>
  <transition name="slide">
    <div v-if="isOpen" class="mobile-navigation">
      <NavigationItems
        @navigationClicked="onNavigationClick"
        :navigationItems="navigationItems"
      ></NavigationItems>
    </div>
  </transition>
</template>

<script>
import NavigationItems from './NavigationItems.vue';

export default {
  name: 'MobileNavigation',
  props: ['navigationItems', 'name'],
  components: { NavigationItems },
  data() {
    return {
      isOpen: false,
      activeTitle: '',
    };
  },
  methods: {
    onNavigationClick() {
      this.toggleNavigation();
    },
    toggleNavigation() {
      this.isOpen = !this.isOpen;
    },
    onCloseIconClick() {
      this.toggleNavigation();
    },
  },
};
</script>

<style scoped lang="scss">
.menu-icon {
  --menu-icon-margin: 1rem;
  --menu-icon-size: 1.5rem;
  width: var(--menu-icon-size);
  height: var(--menu-icon-size);
  margin-left: var(--menu-icon-margin);
  z-index: 10;
  position: relative;

  svg {
    position: absolute;
  }

  .fa-times {
    color: var(--color-secondary);
  }
}
.mobile-navigation {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background-color: var(--color-primary);
}

.page-title-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  h3 {
    margin-bottom: 0;
    color: black;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-leave-active,
.slide-enter-active {
  transition: 0.5s;
}
.slide-enter-from {
  transform: translate(0, -100%);
}
.slide-leave-to {
  transform: translate(0, -100%);
}
</style>
