<template>
  <w-app>
    <body>
      <transition name="slide">
        <TitlePage v-if="showTitlePage"></TitlePage>
        <v-container v-else>
          <Header></Header>
          <Main></Main>
          <Footer></Footer>
          <SideFlaps v-if="$mq === 'largeDesktop'"></SideFlaps>
        </v-container>
      </transition>
    </body>
  </w-app>
</template>

<script>
import Header from './components/Header.vue';
import Main from './components/Main.vue';
import Footer from './components/Footer.vue';
import TitlePage from './views/TitlePage.vue';
import SideFlaps from './components/SideFlaps.vue';

export default {
  name: 'App',
  components: {
    Header,
    Main,
    Footer,
    TitlePage,
    SideFlaps,
  },
  data() {
    return {
      showTitlePage: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.showTitlePage = false;
    }, 2000);
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: 'Youth-Touch';
  src: local('Youth-Touch'), url(./fonts/youth-touch/youth-touch.ttf) format('truetype');
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: var(--color-primary);
}

body {
  margin: 0;
  padding: 0;
  --color-primary: #02b995;
  --color-secondary: #ffffff;
  --color-text: var(--color-secondary);
  --header-height: 5rem;
  --footer-height: 3rem;
}

main {
  justify-content: center;
  display: flex;
}

h1,
h2,
h3 {
  margin-top: 0;
  margin-bottom: 2rem;
  color: var(--color-secondary);
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
  padding: 0;
  color: var(--color-text);
  font-weight: 500;
  line-height: 1.5;
}

.slide-leave-active,
.slide-enter-active {
  transition: 0.5s;
}
.slide-enter-from {
  transform: translate(0, -100%);
}
.slide-leave-to {
  transform: translate(0, -100%);
}
</style>
