export default {
  en: {
    about: {
      headline: 'Hello! I am Max(imilian)',
      paragraph1:
        'Since 2017 I have been passionately developing front-end solutions in a professional context and in numerous private projects.',
      paragraph2:
        'I prefer to use frameworks like React, Angular or VueJS to create component-based, responsive and reusable front ends.',
      paragraph3:
        'I am always open to new experiences and projects in the world of software development. Please contact me!',
    },
    projects: {
      headline: 'Projects',
      paragraph:
        'Here is a small overview of some of my private projects in the world of frontend development.',
      linkText: 'To project',
      quiz: {
        headline: 'Quiz',
        text: 'A simple quiz based on the opentdb API for the questions and answers. You can choose from different categories. At the end, the questions are evaluated with the correct answers.',
      },
      dishWashing: {
        headline: 'Dish Wash App',
        text: 'An app for recording washed items in order to be able to determine exactly who has washed how much in a household. The app was implemented with a SQLite database in order to save the "flush levels" of the various "dishwashers" and to compare them with one another.',
      },
      chat: {
        headline: 'Chat',
        text: 'A simple chat room with Google authentication and real-time connection using Google Firebase and NextJS.',
      },
      recipes: {
        headline: 'Recipe Finder',
        text: 'An app to receive recipe suggestions for a list of ingredients using the API of spoonacular. Angular material was used for the styling',
      },
    },
    contact: {
      headline: 'Contact',
    },
    contactForm: {
      firstName: {
        placeholder: 'Firstname',
      },
      lastName: {
        placeholder: 'Lastname',
      },
      submit: {
        text: 'Send',
      },
      message: {
        placeholder: 'Your message',
      },
      messageSend: {
        title: 'Message send',
        paragraph: 'Thank you for your message',
        closeButton: 'Close',
      },
      messageError: {
        text: 'Error: Your message could not be send',
      },
      formError: {
        message: 'Please enter your name',
        name: 'Please enter your message',
      },
    },
    navigation: {
      home: 'Home',
      about: 'About',
      projects: 'Projects',
      contact: 'Contact',
    },
    footer: {
      imprint: 'Imprint',
      disclaimer: 'Disclaimer',
    },
  },
  de: {
    about: {
      headline: 'Hallo! Ich bin Max(imilian)',
      paragraph1:
        'Seit 2017 entwickle ich leidenschaftlich Frontend Lösungen im beruflichen Context und in zahlreichen privaten Projekten.',
      paragraph2:
        'Ich präferiere dabei die Verwendung von Frameworks wie React, Angular oder VueJS, um komponentenbasierte, responsive und wiederverwendbare Frontends zu erschaffen.',
      paragraph3:
        'Ich bin immer offen für neue Erfahrungen und Projekte in der Welt der Software Entwicklung. Kontaktieren Sie mich gerne!',
    },
    projects: {
      headline: 'Projekte',
      paragraph:
        'Hier findet sich eine kleine Übersicht über einige meiner privaten Projekte in der Welt der Frontend Entwicklung wieder.',
      linkText: 'Zum Projekt',
      quiz: {
        headline: 'Quiz',
        text: 'Ein schlichtes Quiz auf Grundlage der API opentdb für die Fragen und Antworten. Dabei kann unter verschiedenen Kategorien gewählt werden. Zum Ende erfolgt eine Auswertung der Fragen mit den korrekten Antworten.',
      },
      dishWashing: {
        headline: 'Spül App',
        text: 'Eine App zum Erfassen von gespülten Gegenständen, um in einem Haushalt genau bestimmen zu können wer wie viel gespült hat. Realisiert wurde die App mit einer SQLite Datenbank, um die "Spülstände" der verschiedenen "Spüler" zu speichern und miteinander zu vergleichen.',
      },
      chat: {
        headline: 'Chat',
        text: 'Ein simpler Chatraum mit Google Authentifizierung und Echtzeit Verbindung mittels Google Firebase und NextJS.',
      },
      recipes: {
        headline: 'Rezeptfinder',
        text: 'Eine App um zu einer Liste an Zutaten Rezeptvorschläge zu erhalten mittels der API von spoonacular. Für das Styling wurde Angular Material verwendet',
      },
    },
    contact: {
      headline: 'Kontakt',
    },
    contactForm: {
      firstName: {
        placeholder: 'Vorname',
      },
      lastName: {
        placeholder: 'Nachname',
      },
      submit: {
        text: 'Senden',
      },
      message: {
        placeholder: 'Deine Nachricht',
      },
      messageSend: {
        title: 'Nachricht gesendet',
        paragraph: 'Vielen Dank für Ihre Nachricht',
        closeButton: 'Schließen',
      },
      messageError: {
        text: 'Fehler: Ihre Nachricht konnte leider nicht gesendet werden.',
      },
      formError: {
        message: 'Bitte geben Sie ihren Namen an',
        name: 'Bitte geben Sie eine Nachricht ein',
      },
    },
    navigation: {
      home: 'Home',
      about: 'Über mich',
      projects: 'Projekte',
      contact: 'Kontakt',
    },
    footer: {
      imprint: 'Impressum',
      disclaimer: 'Disclaimer',
    },
  },
};
