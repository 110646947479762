import { createApp } from 'vue';
import VueMq from 'vue3-mq';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBars,
  faTimes,
  faChevronRight,
  faChevronLeft,
  faGlobe,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import VueAxios from 'vue-axios';
import WaveUI from 'wave-ui';
import { createI18n } from 'vue-i18n';
import router from './router';
import store from './store';
import App from './App.vue';
import messages from './translations/translations';
import 'wave-ui/dist/wave-ui.css';

library.add(faBars);
library.add(faTimes);
library.add(faChevronRight);
library.add(faChevronLeft);
library.add(faGlobe);
library.add(faEnvelope);

const i18n = createI18n({
  locale: 'de', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages,
});

const app = createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(store)
  .use(router)
  .use(VueMq, {
    breakpoints: {
      mobile: 767,
      desktop: 992,
      largeDesktop: Infinity,
    },
  })
  .use(WaveUI)
  .use(i18n)
  .use(VueAxios, axios);

// eslint-disable-next-line no-new
new WaveUI(app, {
  colors: {
    primary: '#1abc9c',
    secondary: '#ffffff',
  },
});

app.mount('#app');
