<template>
  <NavigationItems :navigationItems="navigationItems"></NavigationItems>
</template>

<script>
import NavigationItems from './NavigationItems.vue';

export default {
  name: 'NavigationBar',
  props: ['navigationItems'],
  components: { NavigationItems },
};
</script>

<style scoped lang="scss">
nav {
  height: 100%;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    li {
      width: 9rem;
      height: 100%;
    }

    a {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      text-decoration: none;

      &:focus-visible {
        font-weight: 800;
        outline: none;
      }
      &:hover {
        font-weight: 800;
      }
    }
  }
}
</style>
