<template>
  <div class="title-page">
    <div>
      <h1 class="title-heading">Maximilian Neitzel</h1>
      <h2 class="title-subheading">Frontend Engineer</h2>
    </div>
  </div>
</template>

<style scoped lang="scss">
.title-page {
  position: absolute;
  background-color: var(--color-primary);
  inset: 0 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  .title-heading {
    font-size: 6rem;
    font-family: 'Youth-Touch', Arial, Helvetica, sans-serif;
    margin-bottom: 0;
    line-height: 0.8;
  }

  .title-subheading {
    margin-top: 0;
    font-size: 2rem;
    font-weight: 400;
  }
}
</style>
