<template>
  <aside>
    <ul>
      <li>
        <a href="mailto:maxneitzel@web.de">
          <font-awesome-icon class="side-flaps-icon" icon="envelope" />
        </a>
      </li>
      <li>
        <a href="https://www.xing.com/profile/Maximilian_Neitzel2" target="_blank">
          <img class="side-flaps-icon" src="@/assets/images/xing-icon.svg" alt="xing-icon" />
        </a>
      </li>
    </ul>
  </aside>
</template>

<style scoped lang="scss">
aside {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding-right: 1em;
  ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 1em;
    justify-content: center;
  }

  .side-flaps-icon {
    width: 2.5rem;
    height: 2.5rem;
    color: black;
    background: white;
    padding: 0.6rem;
    border-radius: 50%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    position: relative;

    &:active {
      top: -2px;
    }
  }
}
</style>
