<template>
  <router-link
    :class="{ active: isActive }"
    :to="navigationItem.link"
    @click="onNavigationItemClick(navigationItem)"
    >{{ $t(navigationItem.title) }}</router-link
  >
</template>

<script>
export default {
  name: 'NavigationItem',
  props: ['navigationItem', 'isActive'],
  methods: {
    onNavigationItemClick(navigationItem) {
      this.$emit('navigationItemClicked', navigationItem);
    },
  },
};
</script>

<style scoped lang="scss">
a {
  text-decoration: none;
  width: 100%;
  display: flex;
  color: white;
  justify-content: center;
  padding: 1rem 0;

  @media screen and (min-width: 768px) {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    text-decoration: none;
    color: black;

    &:hover::before {
      content: '';
      border-color: black;
      border-style: solid;
      border-width: 0 4px 4px 0;
      position: absolute;
      top: 0;
      width: 4rem;
      height: 4px;
      color: black;
    }

    &.active {
      font-weight: 800;
    }

    &:focus {
      font-weight: 800;
      outline: none;
    }
    &:hover {
      font-weight: 800;
    }
  }
}
</style>
