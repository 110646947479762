<template>
  <div class="projects">
    <h1 v-if="$mq === 'desktop'">{{ $t('projects.headline') }}</h1>
    <p class="projects-paragraph">
      {{ $t('projects.paragraph') }}
    </p>
    <div class="project-cards-wrapper">
      <div class="project-cards">
        <ProjectCard
          v-for="project in projects"
          :key="project.title"
          :title="project.title"
          :description="project.description"
          :framework="project.framework"
          :link="project.link"
        ></ProjectCard>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectCard from '../components/ProjectCard.vue';

export default {
  components: { ProjectCard },
  data() {
    return {
      projects: [
        {
          title: 'projects.quiz.headline',
          description: 'projects.quiz.text',
          framework: 'react',
          link: 'https://gitlab.com/NIghtCell/quiz',
        },
        {
          title: 'projects.dishWashing.headline',
          description: 'projects.dishWashing.text',
          framework: 'java',
          link: 'https://gitlab.com/NIghtCell/MySpuelApp',
        },
        {
          title: 'projects.chat.headline',
          description: 'projects.chat.text',
          framework: 'react',
          link: 'https://gitlab.com/NIghtCell/nightcell-chat',
        },
        {
          title: 'projects.recipes.headline',
          description: 'projects.recipes.text',
          framework: 'angular',
          link: 'https://gitlab.com/NIghtCell/food-finder',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.projects {
  h1 {
    margin-bottom: 2rem;
  }

  .projects-paragraph {
    margin-bottom: 2rem;
    text-align: left;
    max-width: 75ch;
    margin: 0 2rem 2rem 2rem;

    @media screen and (min-width: 768px) {
      margin: 2rem auto;
    }
  }
}

.project-cards {
  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 3rem;
    max-width: 50rem;
    margin: 0 auto;
  }
}

.project-cards-wrapper {
  padding: 2rem;
  margin-bottom: 2rem;
  width: 100vw;
  background-color: white;
}
</style>
