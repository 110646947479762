<template>
  <nav>
    <ul v-on:click="onNavigationItemClick">
      <li v-for="navigationItem in navigationItems" :key="navigationItem.title">
        <NavigationItem
          :isActive="isNavigationItemActive(navigationItem)"
          :navigationItem="navigationItem"
          @click="navigationItemClicked(navigationItem)"
        ></NavigationItem>
      </li>
    </ul>
  </nav>
</template>

<script>
import NavigationItem from './NavigationItem.vue';

export default {
  name: 'NavigationItems',
  props: ['navigationItems'],
  components: { NavigationItem },
  data() {
    return {
      activeItem: null,
    };
  },
  methods: {
    onNavigationItemClick() {
      this.$emit('navigationClicked');
    },
    navigationItemClicked(navigationItem) {
      this.activeItem = navigationItem;
    },
    isNavigationItemActive(navigationItem) {
      return navigationItem === this.activeItem;
    },
  },
};
</script>

<style scoped lang="scss">
nav {
  @media screen and (min-width: 768px) {
    height: 100%;
  }
  ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-top: var(--header-height);
    height: 100%;

    @media screen and (min-width: 768px) {
      list-style-type: none;
      margin: 0;
      padding: 0;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
    }
    li {
      --navigation-item-border: 1px solid var(--color-secondary);

      width: 100%;
      border-bottom: var(--navigation-item-border);

      &:first-child {
        border-top: var(--navigation-item-border);
      }

      @media screen and (min-width: 768px) {
        width: 9rem;
        height: 100%;
        border-bottom: 0;

        &:first-child {
          border-top: 0;
        }
      }
    }
  }
}
</style>
