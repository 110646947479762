<template>
  <footer>
    <nav>
      <ul>
        <li>
          <router-link to="/imprint">{{ $t('footer.imprint') }}</router-link>
        </li>
        <li>
          <router-link to="/disclaimer">{{
            $t('footer.disclaimer')
          }}</router-link>
        </li>
      </ul>
    </nav>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  props: {},
};
</script>

<style scoped lang="scss">
footer {
  display: flex;
  justify-content: center;
  background-color: var(--color-secondary);
  height: var(--footer-height);
  font-size: 1rem;

  nav {
    height: 100%;
  }

  ul {
    list-style-type: none;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  li {
    margin: 2rem;
    a {
      text-decoration: none;
      color: black;

      &:hover {
        font-weight: 800;
      }

      &:focus {
        outline: none;
        font-weight: 800;
      }
    }
  }
}
</style>
