<template>
  <div class="image-slider-container">
    <div class="image-slider">
      <button
        v-show="hasPreviousImage()"
        class="slide-control slide-left"
        @keyup="onArrowButtonKeyUp($event, 'left')"
        @click="onLeftSlideClick()"
      >
        <font-awesome-icon icon="chevron-left" />
      </button>
      <div class="images-container">
        <div
          class="image-container"
          v-for="image in imagesList"
          :key="image.src"
        >
          <transition :name="animationDirection" mode="in-out">
            <img
              v-show="isVisibleImage(image)"
              :src="require(`../assets/images/${image.src}`)"
              :alt="image.alt"
            />
          </transition>
        </div>
      </div>
      <button
        v-show="hasNextImage()"
        class="slide-control slide-right"
        @keyup="onArrowButtonKeyUp($event, 'right')"
        @click="onRightSlideClick()"
      >
        <font-awesome-icon icon="chevron-right" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visibleImages: 1,
      activeIndex: 0,
      animationDirection: 'right',
      slideDirection: 'right',
      imagesCount: this.images.length,
      imagesList: this.addIndexToImages(),
      autoslide: () => {},
    };
  },
  mounted() {
    this.startAutoSlide();
    this.registerArrowKeyEventListener();
  },
  name: 'ImageSlider',
  props: ['images'],
  methods: {
    onArrowButtonKeyUp($event, direction) {
      const keyCode = $event.key;
      const validKeyCodes = ['enter', 'space'];
      if (validKeyCodes.includes(keyCode)) {
        if (direction === 'right') {
          this.showNextImage();
        } else {
          this.showPreviousImage();
        }
        this.refreshAutoSlideTimer();
      }
    },
    registerArrowKeyEventListener() {
      window.addEventListener('keyup', ($event) => {
        this.onImageSliderKeyUp($event);
      });
    },
    onImageSliderKeyUp(event) {
      const keyCode = event.key;
      const validKeyCodes = ['ArrowRight', 'ArrowLeft'];
      if (validKeyCodes.includes(keyCode)) {
        if (keyCode === 'ArrowRight') {
          this.showNextImage();
        } else {
          this.showPreviousImage();
        }
        this.refreshAutoSlideTimer();
      }
    },
    addIndexToImages() {
      return this.images.map((image, index) => ({
        ...image,
        index,
      }));
    },
    onLeftSlideClick() {
      this.showPreviousImage();
      this.refreshAutoSlideTimer();
      this.slideDirection = 'left';
    },
    onRightSlideClick() {
      this.showNextImage();
      this.refreshAutoSlideTimer();
      this.slideDirection = 'right';
    },
    isVisibleImage(image) {
      return this.activeIndex === image.index;
    },
    showNextImage() {
      if (this.hasNextImage()) {
        this.animationDirection = 'right';
        this.activeIndex += 1;
      }
    },
    hasNextImage() {
      return this.imagesCount > this.activeIndex + 1;
    },
    showPreviousImage() {
      if (this.hasPreviousImage()) {
        this.animationDirection = 'left';
        this.activeIndex -= 1;
      }
    },
    hasPreviousImage() {
      return this.activeIndex > 0;
    },
    refreshAutoSlideTimer() {
      this.cancelAutoSlide();
      this.startAutoSlide();
    },
    cancelAutoSlide() {
      clearTimeout(this.autoslide);
    },
    handleRightSlideDirection() {
      if (this.hasNextImage()) {
        this.showNextImage();
      } else {
        this.showPreviousImage();
        this.slideDirection = 'left';
      }
    },
    handleLeftSlideDirection() {
      if (this.hasPreviousImage()) {
        this.showPreviousImage();
      } else {
        this.showNextImage();
        this.slideDirection = 'right';
      }
    },
    startAutoSlide() {
      this.autoslide = setTimeout(() => {
        if (this.slideDirection === 'right') {
          this.handleRightSlideDirection();
        } else {
          this.handleLeftSlideDirection();
        }
        this.startAutoSlide();
      }, 3000);
    },
  },
};
</script>

<style scoped lang="scss">
.slide-control {
  background-color: transparent;
  border: 0;
  outline: none;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  z-index: 10;
  width: 2rem;
  height: 2rem;

  &:hover,
  &:focus-visible {
    svg {
      color: var(--color-primary);
    }
  }

  svg {
    --slide-control-size: 100%;
    height: var(--slide-control-size);
    width: var(--slide-control-size);
    color: silver;
  }
}

.slide-right {
  right: -3rem;
}

.slide-left {
  left: -3rem;
}

.images-container {
  width: 15rem;
  height: 15rem;
  position: relative;
}
.image-container {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.image-slider-container {
  background: var(--color-secondary);
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}
.image-slider {
  display: flex;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
}

.left-enter-active {
  animation: left-in 0.5s ease-in;
}
.left-leave-active {
  animation: left-out 0.5s ease-in;
}
@keyframes left-in {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes left-out {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
}

.right-enter-active {
  animation: right-in 0.5s ease-in;
}
.right-leave-active {
  animation: right-out 0.5s ease-in;
}
@keyframes right-out {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}
@keyframes right-in {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
</style>
