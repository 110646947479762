import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import About from '../views/About.vue';
import Projects from '../views/Projects.vue';
import Imprint from '../views/Imprint.vue';
import Contact from '../views/Contact.vue';
import Disclaimer from '../views/Disclaimer.vue';

const routes = [
  {
    path: '/',
    name: 'navigation.home',
    component: Home,
  },
  {
    path: '/about',
    name: 'navigation.about',
    component: About,
  },
  {
    path: '/projects',
    name: 'navigation.projects',
    component: Projects,
  },
  {
    path: '/contact',
    name: 'navigation.contact',
    component: Contact,
  },
  {
    path: '/imprint',
    name: 'footer.imprint',
    component: Imprint,
  },
  {
    path: '/disclaimer',
    name: 'footer.disclaimer',
    component: Disclaimer,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
