<template>
  <div class="imprint">
    <h1 v-if="$mq === 'desktop'">Impressum</h1>
    <div class="imprint-container">
      <h2>
        Impressum Informationspflicht laut § 5 TMG. Reinaldstraße 19, 40882
        Ratingen, Deutschland
      </h2>
      <p>
        ITel.: 01636341809 <br />
        E-Mail: maxneitzel@web.de<br />
        Berufsbezeichnung: Softwareentwickler
      </p>
      <p>
        Quelle: Erstellt mit dem Impressum Generator von AdSimple
        EU-Streitschlichtung Gemäß Verordnung über Online-Streitbeilegung in
        Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie über die
        Online-Streitbeilegungsplattform (OS-Plattform) informieren. Verbraucher
        haben die Möglichkeit, Beschwerden an die Online
        Streitbeilegungsplattform der Europäischen Kommission unter
        http://ec.europa.eu/odr?tid=321281768 zu richten. Die dafür notwendigen
        Kontaktdaten finden Sie oberhalb in unserem Impressum. Wir möchten Sie
        jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind,
        an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
        teilzunehmen.
      </p>

      <p>
        Haftung für Inhalte dieser Website Wir entwickeln die Inhalte dieser
        Webseite ständig weiter und bemühen uns korrekte und aktuelle
        Informationen bereitzustellen. Laut Telemediengesetz (TMG) §7 (1) sind
        wir als Diensteanbieter für eigene Informationen, die wir zur Nutzung
        bereitstellen, nach den allgemeinen Gesetzen verantwortlich. Leider
        können wir keine Haftung für die Korrektheit aller Inhalte auf dieser
        Webseite übernehmen, speziell für jene die seitens Dritter
        bereitgestellt wurden. Als Diensteanbieter im Sinne der §§ 8 bis 10 sind
        wir nicht verpflichtet, die von ihnen übermittelten oder gespeicherten
        Informationen zu überwachen oder nach Umständen zu forschen, die auf
        eine rechtswidrige Tätigkeit hinweisen.
      </p>
      <p>
        Unsere Verpflichtungen zur Entfernung von Informationen oder zur
        Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen
        aufgrund von gerichtlichen oder behördlichen Anordnungen bleiben auch im
        Falle unserer Nichtverantwortlichkeit nach den §§ 8 bis 10 unberührt.
        Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitte
        wir Sie uns umgehend zu kontaktieren, damit wir die rechtswidrigen
        Inhalte entfernen können. Sie finden die Kontaktdaten im Impressum.
      </p>
      <p>
        Haftung für Links auf dieser Website Unsere Webseite enthält Links zu
        anderen Webseiten für deren Inhalt wir nicht verantwortlich sind.
        Haftung für verlinkte Websites besteht für uns nicht, da wir keine
        Kenntnis rechtswidriger Tätigkeiten hatten und haben, uns solche
        Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links
        sofort entfernen würden, wenn uns Rechtswidrigkeiten bekannt werden.
      </p>
      <p>
        Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitte wir
        Sie uns zu kontaktieren. Sie finden die Kontaktdaten im Impressum.
        Urheberrechtshinweis Alle Inhalte dieser Webseite (Bilder, Fotos, Texte,
        Videos) unterliegen dem Urheberrecht der Bundesrepublik Deutschland.
        Bitte fragen Sie uns bevor Sie die Inhalte dieser Website verbreiten,
        vervielfältigen oder verwerten wie zum Beispiel auf anderen Websites
        erneut veröffentlichen. Falls notwendig, werden wir die unerlaubte
        Nutzung von Teilen der Inhalte unserer Seite rechtlich verfolgen.
        Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht
        verletzen, bitten wir Sie uns zu kontaktieren.
      </p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.imprint-container {
  margin: 1rem 2rem;
  text-align: left;

  h2 {
    margin-bottom: 1rem;
  }
}
</style>
