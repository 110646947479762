<template>
  <div class="home">
    <h1>Maximilian Neitzel</h1>
    <h3>Frontend Engineer</h3>
    <div class="profile-picture-wrapper">
      <img
        class="profile-picture"
        alt="maximilian neitzel"
        src="@/assets/images/maximilian-neitzel-foto.1024x1024.jpg"
      />
    </div>
    <TagList :tags="tagList"></TagList>
  </div>
</template>

<script>
import TagList from '../components/TagList.vue';

export default {
  name: 'Home',
  props: {},
  components: { TagList },
  data() {
    return {
      tagList: [
        'HTML5',
        'CSS',
        'JavaScript',
        'TypeScript',
        'Node.js',
        'VueJS',
        'Angular',
        'React',
        'NextJS',
        'Bootstrap',
        'Tailwind',
      ],
    };
  },
};
</script>

<style scoped lang="scss">
h1 {
  margin-bottom: 1rem;
}

h3 {
  margin-bottom: 3rem;
}

.profile-picture-wrapper {
  width: 100vw;
  background-color: white;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.profile-picture {
  border-radius: 100%;
  max-width: 75%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
</style>
