<template>
  <li class="tag">{{ title }}</li>
</template>

<script>
export default {
  props: ['title'],
};
</script>

<style>
.tag {
  color: black;
  font-weight: 500;
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: white;
  margin-right: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  box-shadow: rgba(50, 50, 93, 0.15) 0px 13px 17px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 8px -8px;
}
</style>
