<template>
  <div class="about">
    <h1>{{ $t('about.headline') }}</h1>
    <section class="about-section">
      <p>
        {{ $t('about.paragraph1') }}
      </p>
      <p>
        {{ $t('about.paragraph2') }}
      </p>
      <p>
        {{ $t('about.paragraph3') }}
      </p>
    </section>
    <div class="image-slider-wrapper">
      <ImageSlider :images="images"></ImageSlider>
    </div>
  </div>
</template>

<script>
import ImageSlider from '../components/ImageSlider.vue';

export default {
  name: 'About',
  components: { ImageSlider },
  data() {
    return {
      images: [
        {
          alt: 'html',
          src: 'html.png',
        },
        {
          alt: 'css',
          src: 'css.png',
        },
        {
          alt: 'js3',
          src: 'js3.png',
        },
        {
          alt: 'react',
          src: 'react.png',
        },
        {
          alt: 'angular',
          src: 'angular.png',
        },
        {
          alt: 'vue',
          src: 'vue.png',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.about-section {
  p {
    text-align: left;
  }

  margin: 0 2rem;

  @media screen and (min-width: 768px) {
    margin: 0 auto;
    max-width: 40rem;
  }
}
.about {
  display: flex;
  flex-direction: column;
}

.image-slider-wrapper {
  background-color: white;
  display: block;
  width: 100vw;
  margin: 2rem 0;
  padding: 1rem 0;
}
</style>
