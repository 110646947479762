<template>
  <header>
    <Navigation></Navigation>
    <div class="language-select">
      <LanguageSelect></LanguageSelect>
    </div>
  </header>
</template>

<script>
import Navigation from './Navigation.vue';
import LanguageSelect from './LanguageSelect.vue';

export default {
  name: 'Header',
  props: {},
  components: { Navigation, LanguageSelect },
};
</script>

<style scoped lang="scss">
header {
  display: flex;
  align-items: center;
  background: var(--color-secondary);
  position: fixed;
  top: 0;
  width: 100%;
  height: var(--header-height);
  box-sizing: border-box;
  font-size: 1.5rem;
  z-index: 100;
  margin-bottom: 2rem;

  @media screen and (min-width: 768px) {
    justify-content: center;
  }
}

.language-select {
  position: absolute;
  right: 0;
  margin-right: 1rem;
}
</style>
