<template>
  <MobileNavigation :navigationItems="navigationItems" v-if="$mq === 'mobile'"></MobileNavigation>
  <NavigationBar :navigationItems="navigationItems" v-else></NavigationBar>
</template>

<script>
import MobileNavigation from './MobileNavigation.vue';
import NavigationBar from './NavigationBar.vue';

export default {
  name: 'Navigation',
  components: { MobileNavigation, NavigationBar },
  data() {
    return {
      navigationItems: [
        {
          link: '/',
          title: 'navigation.home',
        },
        {
          link: '/about',
          title: 'navigation.about',
        },
        {
          link: '/projects',
          title: 'navigation.projects',
        },
        {
          link: '/contact',
          title: 'navigation.contact',
        },
      ],
    };
  },
};
</script>
